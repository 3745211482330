import React from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../context';

type LangMenuProps = {
    ctx: Context,
    languages: string[],
};

export function LangMenu(props: LangMenuProps) {
    return (
        <div className='lang_menu'>
            {props.languages.map((lang: string) => (
                lang === props.ctx.language ? (
                    <Link className='lang active_lang' to={props.ctx.getPathWithLanguage(lang)}>
                        {lang}
                    </Link>
                ) : (
                    <Link className='lang' to={props.ctx.getPathWithLanguage(lang)}>
                        {lang}
                    </Link>
                )
            ))}
        </div>
    );
}