import {ArticleMeta, Label, Defaults, Strings, ArticleList} from './article';

function mapArticle(art: any): ArticleMeta {
    return new ArticleMeta(art.article_id, art.author, art.created_on, art.title, art.label, art.tags, art.snippet, art.pinned);
}

function mapLabel(label: any): Label {
    return new Label(label.id, label.name);
}

function mapDefaults(defaults: any): Defaults {
    let res = new Defaults();
    res.label = defaults.label;
    res.language = defaults.language;
    res.available_languages = defaults.available_languages;
    return res;
}

function mapStrings(strings: any): Strings {
    let res = new Strings();
    res.pinned = strings.pinned;
    res.months = strings.months;
    return res;
}

function handleError(json: any): any {
    if (json.data !== undefined) {
        return json;
    }

    if (json.status === undefined || json.reason === undefined) {
        document.location.href = `/err/500?msg=unknown+error`;
        return undefined;
    }

    document.location.href = `/err/${json.status}?msg=${json.reason}`;
    return undefined;
}

let acceptJson = {method: 'GET', headers: {'Accept': 'application/json'}};
let acceptHtmlOrJson = {method: 'GET', headers: {'Accept': 'text/html, application/json'}};

export class Client {
    api_url: string

    constructor(api_url: string = '/api/v1/articles') {
        this.api_url = api_url;
    }

    public getArticles(lang: string, label: string): Promise<ArticleList> {
        return fetch(`${this.api_url}/article?lang=${lang}&label=${label}`, acceptJson)
            .then(res => res.json())
            .then(res => new ArticleList(handleError(res).data.pinned.map(mapArticle), res.data.articles.map(mapArticle)));
    }

    public getArticleById(lang: string, id: string): Promise<ArticleMeta> {
        return fetch(`${this.api_url}/article/${id}?lang=${lang}`, acceptJson)
            .then(res => res.json())
            .then(res => mapArticle(handleError(res).data.article));
    }

    public getContent(lang: string, id: string): Promise<string> {
        return fetch(`${this.api_url}/article/${id}/content?lang=${lang}`, acceptHtmlOrJson)
            .then(res => res.text());
    }

    public getLabels(lang: string): Promise<Label[]> {
        return fetch(`${this.api_url}/label?lang=${lang}`, acceptJson).then(res => res.json()).then(res => handleError(res).data.labels.map(mapLabel));
    }

    public getDefaults(lang: string = 'en'): Promise<Defaults> {
        return fetch(`${this.api_url}/defaults?lang=${lang}`, acceptJson).then(res => res.json()).then(res => mapDefaults(handleError(res).data.defaults));
    }
    
    public getStrings(lang: string): Promise<Strings> {
        return fetch(`${this.api_url}/strings?lang=${lang}`, acceptJson).then(res => res.json()).then(res => mapStrings(handleError(res).data.strings));
    }

    public static getClient() : Client {
        return globalCli;
    }
}

const globalCli = new Client('/api/v1/articles');
// const globalCli = new Client('http://localhost:8101/api/v1/articles');
