import React from 'react';
import { Application } from './components/application';
import { ErrorView } from './components/error';
import './fonts.css';
import './application.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

function Routing() {
  return (
    <Router>
      <Routes>
        <Route path='/err/:errCode' element={ <ErrorView /> }  />
        <Route path='/article/:articleId' element={ <Application /> } />
        <Route path='/:lang/article/:articleId' element={<Application />} />
        <Route path='/:lang/:label' element={<Application />} />
        <Route path='/:label' element={<Application />} />
        <Route path='/' element={<Application />}>
          
        </Route>
      </Routes>
    </Router>
  );
}

export default Routing;
