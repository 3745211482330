import React from 'react';
import { Link } from 'react-router-dom';
import { Label } from '../article';

type MenuProps = {
    lang: string,
    labels: Label[],
    activeLabel: string
}

function createLabelUrl(lang: string, label: Label): string {
    return `/${lang}/${label.id}`;
}

export function Menu(props: MenuProps) {
    return (
        <div className='menu'>
            {props.labels.map((label: Label) => {
                if (label.id === props.activeLabel) {
                    return (<div className='menu_item active_item'><Link to={createLabelUrl(props.lang, label)}>{label.name}</Link></div>);
                }
                return (<div className='menu_item'><Link to={createLabelUrl(props.lang, label)}>{label.name}</Link></div>);
            })}
        </div>
    );
}