import React, { useEffect, useState } from 'react';
import { HeadPanel } from './headpanel';
import { LabelFeed } from './labelfeed';
import { Client } from '../client';
import { Strings, Defaults } from '../article';
import { useParams } from 'react-router-dom';
import { StandaloneArticle } from './standalone';
import { Context, ViewMode } from '../context';

export function Application() {
  let [lang, setLang] = useState<string>('');
  let [label, setLabel] = useState<string>('');
  let [strings, setStrings] = useState<Strings>(new Strings());
  let [languages, setLanguages] = useState<string[]>([]);
  let [ctx, setCtx] = useState<Context>(new Context("", ViewMode.Feed, "", ""));

  let params: any = useParams();

  useEffect(() => {
    Client.getClient().getDefaults().then((defaults: Defaults) => {
      if (params.lang === undefined) {
        setLang(defaults.language);
      } else {
        setLang(params.lang);
      }

      if (params.label === undefined) {
        setLabel(defaults.label);
      } else {
        setLabel(params.label);
      }

      setLanguages(defaults.available_languages);
    });
  }, [params.lang, params.label]);

  useEffect(() => {
    if (lang === '') {
      return;
    }

    Client.getClient().getStrings(lang).then((strings: Strings) => {
      setStrings(strings);
    });
  }, [lang]);

  useEffect(() => {
    let mode = params.articleId !== undefined ? ViewMode.StandaloneArticle : ViewMode.Feed;
    setCtx(new Context(lang, mode, label, params.articleId));
  }, [params.articleId, lang, label]);

  return (
    <div className='app'>
      <HeadPanel ctx={ctx} availableLangs={languages} />
      <div className='content'>
        {
          params.articleId !== undefined ?
            (<StandaloneArticle articleId={params.articleId} lang={lang} strings={strings} />)
            :
            (<LabelFeed label={label} lang={lang} strings={strings} />)
        }
      </div>
    </div>
  );
}
