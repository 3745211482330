import React, { useEffect, useState } from 'react';
import { Label } from '../article';
import { Client } from '../client';
import { Logo } from './logo';
import { LangMenu } from './langmenu';
import { Menu } from './menu';
import { Context } from '../context';

type HeadPanelProps = {
    ctx: Context,
    availableLangs: string[]
};

export function HeadPanel(props: HeadPanelProps) {
    let [labels, setLabels] = useState<Label[]>([]);

    useEffect(() => {
        if (props.ctx.language === '') {
            return;
        }

        Client.getClient().getLabels(props.ctx.language).then((labels: Label[]) => {
            setLabels(labels);
        });
    }, [props.ctx.language]);

    return (
        <div className="head_panel">
            <div className="head_panel_content">
                <Logo />
                <LangMenu ctx={props.ctx} languages={props.availableLangs} />
                <div className='head_panel_right'>
                    <Menu lang={props.ctx.language} labels={labels} activeLabel={props.ctx.label} />
                </div>
            </div>
        </div>
    );
}