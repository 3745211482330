import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';


function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export const ErrorView = withRouter((props: any) => {
  let params = useParams();
  const search = useLocation().search;
  const encodedMsg = new URLSearchParams(search).get('msg');
  let msg: string = '';
  if (encodedMsg !== null) {
    msg = encodedMsg;
  }

  return (
    <div className='error_view'>
      <div className='error_code'>
        {params.errCode}
      </div>
      <div className='error_msg'>
        {msg}
      </div>
      <div className='error_back' onClick={props.history.goBack}>
        Go Back
      </div>
    </div>
  );
});
