import React, { useEffect, useState } from 'react';
import { ArticleMeta, Article, Strings } from '../article';
import { Client } from '../client';
import { FullArticle } from './article';

type StadaloneArticleProps = {
    articleId: string,
    lang: string,
    strings: Strings
};

export function StandaloneArticle(props: StadaloneArticleProps) {
    let [articleContent, setArticleContent] = useState<Article>(new Article(new ArticleMeta('', '', 0, '', '', [], '', false), ''));

    useEffect(() => {
        if (props.lang === '') {
            return;
        }

        let cli = Client.getClient();

        cli.getArticleById(props.lang, props.articleId.toString()).then((article: ArticleMeta) => {
            cli.getContent(props.lang, props.articleId).then((content: string) => {
                setArticleContent(new Article(article, content));
            });
        });
    }, [props.lang, props.articleId]);


    return (
        <div className="standalone_article">
                <FullArticle lang={props.lang} article={articleContent} strings={props.strings} />
        </div>
    );
}