export enum ViewMode {
    Feed,
    StandaloneArticle
}

export class Context {
    public language: string;
    public mode: ViewMode;
    public label: string;
    public article: string;

    constructor(lang: string, mode: ViewMode, label: string, article: string) {
        this.language = lang;
        this.mode = mode;
        this.label = label;
        this.article = article;
    }

    public getPathWithLanguage(lang: string): string {
        if (this.mode === ViewMode.Feed) {
            return `/${lang}/${this.label}`;
        }
        return `/${lang}/article/${this.article}`;
    }
}