export class ArticleMeta {
    id: string
    author: string
    createdOn: number
    title: string
    label: string
    tags: string[]
    snippet: string
    pinned: boolean = false
    
    constructor(id: string,
                author: string,
                created_on: number,
                title: string,
                label: string,
                tags: string[],
                snippet: string,
                pinned: boolean) {
        this.id = id;
        this.author = author;
        this.createdOn = created_on;
        this.title = title;
        this.label = label;
        this.tags = tags;
        this.snippet = snippet;
        this.pinned = pinned;
    }
}

export class Article {
    article: ArticleMeta
    content: string

    constructor(article: ArticleMeta, content: string) {
        this.article = article;
        this.content = content;
    }
}

export class Label {
    id: string
    name: string

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

export class Defaults {
    label: string = 'home'
    language: string = 'pl'
    available_languages: string[] = []
}

export class Strings {
    pinned: string = ""
    months: string[] = []
}

export class ArticleList {
    pinned: ArticleMeta[] = []
    articles: ArticleMeta[] = []

    constructor(pinned: ArticleMeta[], articles: ArticleMeta[]) {
        this.pinned = pinned;
        this.articles = articles;
    }
}