import React, { useEffect, useState } from 'react';
import { ArticleFeed } from './article';
import { ArticleMeta, Article, ArticleList, Strings } from '../article';
import { Client } from '../client';

type LabelFeedProps = {
    label: string
    lang: string,
    strings: Strings
};

export function LabelFeed(props: LabelFeedProps) {
    let [pinnedArticles, setPinnedArticles] = useState<Article[]>([]);
    let [articles, setArticles] = useState<ArticleMeta[]>([]);

    useEffect(() => {
        if (props.lang === '' || props.label === '') {
            return;
        }

        let cli = Client.getClient();

        cli.getArticles(props.lang, props.label).then((articles: ArticleList) => {
            setArticles(articles.articles);
            Promise.all(
                articles.pinned.map(
                    async (article: ArticleMeta) => (
                        new Article(article, await cli.getContent(props.lang, article.id))
                    )
                )
            ).then((articles: Article[]) => {
                setPinnedArticles(articles);
            });
        });
    }, [props.lang, props.label]);


    return (
        <div className="label_feed">
            <ArticleFeed lang={props.lang} articles={articles} pinned={pinnedArticles} strings={props.strings} />
        </div>
    );
}