import React from 'react';
import { Link } from 'react-router-dom';
import {ArticleMeta, Article, Strings} from '../article';
import {ReactComponent as PinImage} from '../assets/pin.svg';

type ArticleFeedProps = {
    articles: ArticleMeta[],
    lang: string,
    pinned: Article[],
    strings: Strings
};

export function ArticleFeed(props: ArticleFeedProps) {
    return (<div className='article_list'>
        <div className='pinned_articles'>
            {props.pinned.map(article => (<FullArticle lang={props.lang} article={article} strings={props.strings}></FullArticle >))}
        </div>
        <div className='articles_snipets'>
            {props.articles.map(article => (<ArticleSnippet  lang={props.lang} article={article} strings={props.strings}></ArticleSnippet>))}
        </div>
    </div>);
}

function formatDate(time: number, strings: Strings): string {
    let artDate = new Date(time * 1000);
    return artDate.getDate() + ' ' + strings.months[artDate.getMonth()] + ' ' + artDate.getFullYear();
}

function makeArticleUrl(lang: string, articleId: string): string {
    return `/${lang}/article/${articleId}`;
}

type ArticleSnippetProps = {
    article: ArticleMeta,
    lang: string,
    strings: Strings
};

export function ArticleSnippet(props: ArticleSnippetProps) {
    return (
        <div className='article_header'>
            <div className='article_title'>
                <Link to={makeArticleUrl(props.lang, props.article.id)}>{props.article.title}</Link>
            </div>
            <div className='article_meta'>
                <span className='article_author'>{props.article.author}</span>
                <span className='separator'>·</span>
                <span className='article_date'>{formatDate(props.article.createdOn, props.strings)}</span>
                { props.article.pinned &&
                    <span>
                        <span className='separator'>·</span>
                        <span className='article_pinned'>
                            <span className='icon'>
                                <PinImage />
                            </span>
                             {props.strings.pinned}</span>
                    </span>
                }
            </div>
            <div className='article_snippet'>
                {props.article.snippet}
            </div>
        </div>
    );
}

type FullArticleProps = {
    lang: string,
    article: Article,
    strings: Strings
};

export function FullArticle(props: FullArticleProps) {
    return (
        <div className='article'>
            <ArticleSnippet lang={props.lang} article={props.article.article} strings={props.strings}></ArticleSnippet>
            <div className='article_content' dangerouslySetInnerHTML={{__html: props.article.content}} />
        </div>
    );
}